.user_system_rwd {
    .us_addressChooserWthHeader {
        margin-top: 25px;
        width: span(6 of 6);

        .us_addressChooserButtons {
            margin-top: 25px;

            .us_cancelButton {
                width: span(6 of 6);

                @include susy-breakpoint($breakpoint-large...) {
                    float: left;
                    width: span(3 of 12);
                }
            }

            .us_confirmButton {
                width: span(6 of 6);
                margin-top: 10px;

                @include susy-breakpoint($breakpoint-large...) {
                    margin-top: 0;
                    float: right;
                    width: span(3 of 12 last);
                }
            }
        }
    }

    .us_addressChooser__option {
        background-color: $grey100;
        margin: 4px 0;
        padding: 10px;
        cursor: pointer;

        &.us_addressChooser__option--selected {
            background-color: $grey200;
        }

        .us_addressChooser__optionAddress {
            margin-left: 10px;
            vertical-align: top;
            display: inline-block;
        }

        .us_addressChooser__input {
            display: inline-block;
        }
    }
}
