.user_system_rwd {

    &.us_reducedHeaderAndFooter {
        visibility: visible !important;
        .contentFullWidth {
            float: left;
            width: 100%;
        }
    }

    .us_headlineBox {
        padding: 16px 0;
    }

    .loginContent {

        .user_textDecorationUnderline {
          text-decoration: underline;
        }

        .p_form {
            .us_infoButton {
              margin-top: 1.5%;
            }

            .user_newsletterAllowanceInput {
              position: absolute;
              margin: 10px 10px 10px 15px;
            }

            .user_newsletter_permission_label {
              font-size: 13px;
              line-height: 18px;
              width: auto;
              margin: 7px 0 0 50px;
              display: inline-block;
            }

        }
    }

    /* Reauthenticate */
    .us_reducedHeader {
       overflow: hidden;
       margin-top: 20px;
    }

    .us_reducedHeader__logo {
       float: left;
       margin-right: 15px;
       text-decoration: none;

       .us_reducedHeader__svg {
           height: 52px !important;
           width: 140px !important;
           margin-top: 5px !important;

           path {
               fill: $red200 !important;
           }
       }
    }

    .us_reauthenticationFormHeadline {
        margin-bottom: 2px;
    }

    .us_reauthenticationForm {
        @include susy-breakpoint($breakpoint-large...) {
            margin-bottom: 84px;
        }
    }

    .us_reauthenticationLostPasswordLinkRow {
        margin-bottom: 18px;
    }

    .us_hintHighestSecurityLevel {
        margin: 0 0 12px 0;

        @include susy-breakpoint($breakpoint-large...) {
            margin: 6px 0 28px 0;
        }
    }

    .us_loginApiFooter {
        width: 100%;
        float: left;
        border-top: 1px solid $grey200;
        margin-bottom: 8px;
        margin-top: 16px;
        padding-top: 24px;
    }

    .us_spacerTop5px {
        margin-top: 5px;
    }

    .us_spacerTop8px {
        margin-top: 8px;
    }

    .us_spacerTop10px {
        margin-top: 10px;
    }

    .us_spacerTop20px {
        margin-top: 20px;
    }

    .us_spacerTop30px {
        margin-top: 30px;
    }

    .us_spacerTop40px {
        margin-top: 40px;
    }

    .us_spacerTop60px {
        margin-top: 60px;
    }

    .us_spacerTop80px {
        margin-top: 80px;
    }

    .us_spacerBottom4px {
        margin-bottom: 4px;
    }

    .us_spacerBottom10px {
        margin-bottom: 10px;
    }

    .us_spacerBottom20px {
        margin-bottom: 20px;
    }

    .us_spacerBottom30px {
        margin-bottom: 30px;
    }

    .us_adviceSpacer {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .us_clearBoth {
        clear: both;
    }

    .us_hide {
        display: none;
    }

    .us_hideOnSM {
        display: none;

        @include susy-breakpoint($breakpoint-large...) {
            display: block;
        }
    }

    .us_spinnerButton {
        position: relative;

        &.us_spinnerButtonInLane {
            width: span(6 of 6);
        }

        &.us_right {
            margin-bottom: 10px;
        }

        &.us_left {
            margin-bottom: 50px;
        }

        @include susy-breakpoint($breakpoint-large...) {

            &.us_spinnerButtonInLane {
                width: 175px;
            }

            &.us_right {
                float: right;
                margin-bottom: 0;
            }

            &.us_left {
                float: left;
                margin-bottom: 0;
            }
        }

        .us_spinnerButtonOverlay {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 16px;
            height: 50%;
            margin: auto;
        }
    }

    .us_lazySpinner {
        margin: auto;
    }

    .us_gutter {
        padding: 0 $inner-grid-padding;
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}

.us_popup {
  visibility: visible !important;
}

.us_layerCurtain {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8499;
}

.touch .us_layerCurtain {
    cursor: pointer;
}

.p_form {

    .p_formGroup {

        &.us_formGroupContainer {
            margin-top: 0;
        }
    }
}

.us_hintColorGrey {
    color: $grey400;
}
