.user_system_rwd .loginContent {
  .user_sel_loginHelpModule {
    margin-bottom: 2px;
  }

  .us_accordion_content {
    padding: 12px 0 28px 0;

    @include susy-breakpoint($breakpoint-large...) {
      padding: 24px 0 40px 0;
    }
  }
}