.user_system_rwd {
    .us_updatePhoneNumberWithoutParcelShopSms {
        .us_updatePhoneNumberField {
            width: 66%;
        }
    }
    .us_updatePhoneNumber {
        margin-top: 32px;

        @include susy-breakpoint($breakpoint-large...) {
            margin-top: 64px;
        }

        .us_updatePhoneNumberField {
            width: 53%;
        }

        .us_updatePhoneNumberFieldEmpty {
            width: 52%;

            @include susy-breakpoint($breakpoint-medium...) {
                width: 51%;
            }
        }
    }
}